/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Agricon
		Date:		8th November 2017
		Author:		Edge Marketing Solutions

===========================================================================*/

	/* [Global Variables] - http://sass-lang.com/guide */

	$color-red: rgb(229, 26, 30);
	$color-blue-dark: rgb(6, 85, 153);
	$color-blue-light: rgb(4, 128, 200);
	$color-grey: rgb(88, 89, 91);
	$color-yellow: rgb(255, 240, 24);
	$color-green: rgb(67, 166, 71);

	$font-featured: 'Raleway', sans-serif;

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		color: $color-grey;
		font-family: arial, sans-serif;
		font-size: 0.9rem;
	}

	a
	{
		-webkit-transition: all 150ms ease-in-out;
		-moz-transition: all 150ms ease-in-out;
		-ms-transition: all 150ms ease-in-out;
		-o-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;

		color: $color-blue-light;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6
	{
		font-family: $font-featured;
	}

	h1
	{
		margin: 0 0 30px 0;

		font-size: 3.5rem;
		color: $color-blue-light;
	}

	h2
	{
		margin: 30px 0;

		font-size: 2.5rem;
		color: $color-blue-light;
	}

	h3
	{
		margin: 30px 0;

		font-size: 1.8rem;
		color: $color-grey;
	}

	h4
	{
		margin: 30px 0;

		font-size: 1.8rem;
		color: $color-blue-light;
	}

	h5
	{
		margin: 30px 0 5px 0;

		font-size: 1.3rem;
		color: $color-grey;
		font-weight: 700;
	}

	h6
	{
		margin: 30px 0 5px 0;

		font-size: 1.3rem;
		color: $color-blue-light;
		font-weight: 700;
	}

	p.lead
	{
		font-family: $font-featured;
		font-weight: 400;
	}

	img
	{
		max-width: 100%;
	}

	img.svg-responsive
	{
		width: 100% \9;
	}

	/*OPTIONAL FULL WIDTH NAVIGATION - Add nav-justified to <ul class="nav navbar-nav">*/
	.navbar-nav.nav-justified > li {
		float: none!important;
	}

	/* Generated by Glyphter (http://www.glyphter.com) on Tue Sep 06 2016*/
	@font-face {
		font-family: 'social icons';
		src: url('../fonts/social-icons.eot');
		src: url('../fonts/social-icons.eot?#iefix') format('embedded-opentype'),
		url('../fonts/social-icons.woff') format('woff'),
		url('../fonts/social-icons.ttf') format('truetype'),
		url('../fonts/social-icons.svg#social-icons') format('svg');
		font-weight: normal;
		font-style: normal;
	}
	[class^="social-icon-"]::before, [class*=" social-icon-"]::before {
		display: inline-block;
		font-family: 'social icons';
		font-style: normal;
		font-weight: normal;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.social-icon-square-facebook::before {content:'\0041';}
	.social-icon-square-google-plus::before {content:'\0042';}
	.social-icon-square-linkedin::before {content:'\0043';}
	.social-icon-square-pinterest::before {content:'\0044';}
	.social-icon-square-twitter::before {content:'\0045';}
	.social-icon-square-youtube::before {content:'\0046';}
	.social-icon-circle-facebook::before {content:'\0047';}
	.social-icon-circle-google-plus::before {content:'\0048';}
	.social-icon-circle-linkedin::before {content:'\0049';}
	.social-icon-circle-pinterest::before {content:'\004a';}
	.social-icon-circle-twitter::before {content:'\004b';}
	.social-icon-circle-youtube::before {content:'\004c';}
	.social-icon-rounded-facebook::before {content:'\004d';}
	.social-icon-rounded-google-plus::before {content:'\004e';}
	.social-icon-rounded-linkedin::before {content:'\004f';}
	.social-icon-rounded-pinterest::before {content:'\0050';}
	.social-icon-rounded-twitter::before {content:'\0051';}
	.social-icon-rounded-youtube::before {content:'\0052';}

	[class^="social-icon-circle-"]::before, [class*=" social-icon-circle-"]::before {
		border-radius: 100%;
	}

	[class^="social-icon-rounded-"]::before, [class*=" social-icon-rounded-"]::before {
		border-radius: 15%;
	}

	.btn
	{
		border-radius: 0;
		cursor: pointer;
		white-space: normal;
	}

	.btn-info
	{
		background-color: $color-blue-light;
		border: none;

		cursor: pointer;
	}

		.btn-info:hover
		{
			background-color: $color-blue-dark;
		}

	.btn-primary
	{
		background-color: $color-blue-dark;
		border: none;

		cursor: pointer;
	}

		.btn-primary:hover
		{
			background-color: $color-blue-light;
		}

	.btn-success
	{
		background-color: $color-green;
		color: white!important;

		cursor: pointer;
	}

	.btn-danger
	{
		background-color: $color-red;

		cursor: pointer;
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		h1
		{
			font-size: 2.8rem;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		h1
		{
			font-size: 2.8rem;
		}

		h2
		{
			font-size: 2.0rem;
		}

		h3,
		h4
		{
			font-size: 1.4rem;
		}

		h5,
		h6
		{
			font-size: 1.0rem;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		h1
		{
			font-size: 2.8rem;
		}

		h2
		{
			font-size: 2.0rem;
		}

		h3,
		h4
		{
			font-size: 1.4rem;
		}

		h5,
		h6
		{
			font-size: 1.0rem;
		}

		//Bootstrap - hide only mobile
		.d-xs-none
		{
			display: none;
		}
	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.header
	{
		width: 100%;
		height: 500px;
		position: relative;
	}

		.header-homepage
		{
			height: 680px;

			border-bottom: 8px solid $color-red;
		}

		.header .h-logo
		{
			position: absolute;
			top: -3px;
			left: 0;
			width: 100%;
			z-index: 1000;
		}

		.header .h-statement
		{
			align-self: center;

			color: rgb(0, 0, 0);
			font-family: $font-featured;
			font-size: 0.8rem;
		}

	.subpage-banner,
	.swiper-container
	{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

		.subpage-banner::after,
		.swiper-container::after
		{
			width: 100%;
			height: 100%;
			content: ' ';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;

			background-color: rgba(35, 31, 32, 0.2);
		}

	.swiper-slide-image
	{
		height: 100%;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.header .navigation
	{
		width: 100%;
		z-index: 1000;

		background-color: transparentize($color-blue-light, 0.2);
	}

	.header .page-title
	{
		position: absolute;
		bottom: 20px;
		width: 100%;
	}

		.header .page-title h1
		{
			margin: 0 0 5px 0;

			color: rgb(255, 255, 255);
			text-transform: uppercase;
		}

	.breadcrumb
	{
		padding: 0;
		margin: 0;

		background-color: transparent;
		border: none;
		border-radius: 0;

		color: rgb(255, 255, 255);
		font-size: 0.7rem;
	}

	.breadcrumb-item.active,
	.breadcrumb a
	{
		color: rgb(255, 255, 255);
	}

	#blog-listings-container h3
	{
		margin-top: 0;
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.header
		{
			height: 400px;
		}

		.header-homepage
		{
			height: 500px;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.header
		{
			height: 350px;
		}

		.header-homepage
		{
			height: 450px;
		}

			.header h1
			{
				font-size: 2.6rem;
			}

		.header .h-statement
		{
			text-align: right;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.header,
		.header-homepage
		{
			height: auto;

			text-align: center;
		}

		.subpage-banner
		{
			width: 100%;
			height: 300px;
			position: relative;
		}

		.swiper-container
		{
			width: 100%;
			height: 180px;
			position: relative;
		}

			.header h1
			{
				font-size: 2.4rem;
				text-align: left;
			}

		.header .navigation
		{
			padding-bottom: 20px;

			background-color: $color-blue-light;
		}

		.header .h-logo
		{
			position: relative;
			width: 200px;
			display: inline-block;
			z-index: 1000;
		}

		.header .h-statement
		{
			color: rgb(255, 255, 255);
		}
	}

	/*=========================================================================*/

	/* [Navigation Template Styles] */

	.navbar-expand-md .navbar-nav
	{
		width: 100%;
		justify-content: flex-end;
	}

	.navbar-expand-md .navbar-nav .nav-link
	{
		padding: 0.7rem 1.3rem;

		color: rgb(255, 255, 255);
		text-transform: uppercase;
		font-family: $font-featured;
	}

	.navbar-expand-md .navbar-nav .nav-item:first-child .nav-link
	{
		padding-left: 0;
	}

	.navbar-expand-md .navbar-nav .nav-item:last-child .nav-link
	{
		padding-right: 0;
	}

	.navbar-light .navbar-nav .show > .nav-link,
	.navbar-light .navbar-nav .active > .nav-link,
	.navbar-light .navbar-nav .nav-link.show,
	.navbar-light .navbar-nav .nav-link.active,
	.navbar-light .navbar-nav .nav-link:focus,
	.navbar-light .navbar-nav .nav-link:hover
	{
		color: $color-yellow;
	}

	.dropdown-item.active,
	.dropdown-item:active
	{
		background-color: transparent;

		color: $color-blue-light;
	}

	.dropdown-item
	{
		padding: 0.25rem 1rem;

		text-align: center;
	}

	.navbar-light .navbar-toggler
	{
		width: 100%;
		margin-top: 10px;

		color: rgb(255, 255, 255);
		border-color: rgb(255, 255, 255);
	}

	/* Large desktop */
	@media (min-width: 1200px) {
		.dropdown-menu
		{
			left: 50%;
			transform: translateX(-50%);
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.dropdown-menu
		{
			left: 50%;
			transform: translateX(-50%);
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.dropdown-menu
		{
			left: 50%;
			transform: translateX(-50%);
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.navbar-expand-md .navbar-nav
		{
			background-color: transparentize($color-blue-dark, 0.7);
		}

		.navbar-expand-md .navbar-nav .nav-link
		{
			padding: 0.7rem 0;
		}
	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.page
	{
		width: 100%;
		padding: 45px 0 55px 0;

		border-bottom: 10px solid $color-red;
	}

	.subnav
	{
		padding-top: 30px;
		position: relative;

		font-family: $font-featured;
		font-size: 1.0rem;
	}

		.subnav::before
		{
			width: 100px;
			height: 8px;
			position: absolute;
			top: 0;
			left: 0;
			content: ' ';

			background-color: $color-red;
		}

		.subnav ul
		{
			margin: 0;
			padding: 0;

			list-style-type: none;
		}

			.subnav ul > li
			{
				margin: 10px 0;
			}

			.subnav ul > li > a
			{
				color: $color-grey;
			}

			.subnav ul > li > a:hover,
			.subnav ul > li.active > a
			{
				color: $color-green;
				text-decoration: none;
			}

				.subnav ul > li > ul > li
				{
					margin: 10px 0;
				}

				.subnav ul > li > ul > li:last-child
				{
					border-bottom: solid 1px #e1e1e1;
					padding-bottom: 10px;
				}

				.subnav ul > li > ul > li > a
				{
					color: $color-grey;
					font-size: 12px;
				}

				.subnav ul > li > ul > li > a:hover,
				.subnav ul > li > ul > li.active > a
				{
					color: $color-green;
					text-decoration: none;
				}

	.listing
	{
		margin-bottom: 30px;

		border: 1px solid transparentize($color-grey, 0.7);
	}

		.listing .listing-thumbnail
		{
			padding: 10px;
			border-bottom: 1px solid transparentize($color-grey, 0.7);
		}

		.listing .listing-gallery-thumbnail
		{
			padding: 5px 10px;
		}

		.listing .listing-content
		{
			padding: 20px 10px;

			text-align: left;
		}

		.listing h3
		{
			margin: 0 0 5px 0;

			font-size: 1.4rem;
			color: $color-grey;
		}

			.listing h3 a
			{
				color: $color-grey;
			}

			.listing h3 a:hover
			{
				color: $color-green;
				text-decoration: none;
			}

		.listing .btn-action
		{
			display: block;
			-webkit-border-radius:  0;
			-moz-border-radius:  0;
			border-radius:  0;

			font-family: $font-featured;
		}

	.product-title
	{
		margin: 0 0 10px 0;

		color: $color-green;
	}

	.product-features-list
	{
		font-size: 1.2rem;
		font-family: $font-featured;
		font-weight: 400;
	}

	.table-title
	{
		display: block;
		margin: 30px 0 0 0;
		padding: 0.5rem 0.75rem;

		background : -moz-linear-gradient(0% 50% 0deg,rgba(6, 85, 153, 1) 0%,rgba(4, 127, 199, 1) 100%);
		background : -webkit-linear-gradient(0deg, rgba(6, 85, 153, 1) 0%, rgba(4, 127, 199, 1) 100%);
		background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(6, 85, 153, 1) ),color-stop(1,rgba(4, 127, 199, 1) ));
		background : -o-linear-gradient(0deg, rgba(6, 85, 153, 1) 0%, rgba(4, 127, 199, 1) 100%);
		background : -ms-linear-gradient(0deg, rgba(6, 85, 153, 1) 0%, rgba(4, 127, 199, 1) 100%);
		-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#065599', endColorstr='#047FC7' ,GradientType=0)";
		background : linear-gradient(90deg, rgba(6, 85, 153, 1) 0%, rgba(4, 127, 199, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#065599',endColorstr='#047FC7' , GradientType=1);

		color: rgb(255, 255, 255);
		font-weight: 400;
	}

	#category-listings-container
	{
		display: flex;
		width: 100%;
	}

	.listing-category
	{
		flex-basis: 25%;
		padding: 15px 10px 0 10px;
	}

		.listing-category:hover .listing-thumbnail::after
		{
			background-color: rgba(35, 31, 32, 0.4);
		}

		.listing-category .listing-thumbnail
		{
			position: relative;
		}

		.listing-category .listing-thumbnail::after
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			-webkit-transition: all 200ms ease-in-out;
			-moz-transition: all 200ms ease-in-out;
			-ms-transition: all 200ms ease-in-out;
			-o-transition: all 200ms ease-in-out;
			transition: all 200ms ease-in-out;
			background-color: rgba(35, 31, 32, 0.7);
		}

		.listing-category .listing-content
		{
			position: absolute;
			bottom: 0px;
		}

			.listing-category .listing-content h3
			{
				margin: 0 0 40px 0;
				padding: 20px;
				position: relative;
				color: rgb(255, 255, 255);
			}

			.listing-category .listing-content h3::after
			{
				position: absolute;
				content: ' ';
				bottom: 0;
				left: 20px;
				width: 100px;
				height: 6px;

				background-color: rgb(255, 255, 255);
			}

	.page-homepage
	{
		padding-top: 100px;
		padding-bottom: 120px;
	}

	.page-homepage h1
	{
		color: $color-grey;
		font-size: 3rem;
		line-height: 2.8rem;
	}

	.feature-image
	{
		position: relative;
	}

		.feature-image::after
		{
			content: ' ';
			position: absolute;
			width: 140%;
			height: 140%;
			bottom: -60px;
			left: -60px;
			z-index: -1;

			background-image: url(../images/grid-bg.svg);
			background-repeat: no-repeat;
			background-position: bottom left;
		}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.listing-category
		{
			flex-basis: 25%;
			padding: 10px 5px 0 5px;
		}

		.listing-category .listing-content h3
		{
			margin: 0 0 30px 0;
			padding: 20px;
			position: relative;
			color: rgb(255, 255, 255);
			font-size: 1.3rem;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.listing-category
		{
			flex-basis: 25%;
			padding: 10px 5px 0 5px;
		}

		.listing-category .listing-content h3
		{
			margin: 0 0 20px 0;
			font-size: 1.1rem;
		}

		.page-homepage
		{
			padding-top: 70px;
			padding-bottom: 80px;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		#category-listings-container
		{
			display: block;
			width: 100%;
		}

		.listing-category
		{
			width: 100%;
			display: block;
			padding: 10px 5px 0 5px;
		}

		.listing-category .listing-content h3
		{
			margin: 0 0 20px 0;
			padding: 20px;
			font-size: 1.4rem;
		}

		.page-homepage
		{
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer
	{
		width: 100%;
		padding: 45px 0 0 0;
		position: relative;

		background : -moz-linear-gradient(0% 50% 0deg,rgba(6, 85, 153, 1) 0%,rgba(4, 127, 199, 1) 100%);
		background : -webkit-linear-gradient(0deg, rgba(6, 85, 153, 1) 0%, rgba(4, 127, 199, 1) 100%);
		background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(6, 85, 153, 1) ),color-stop(1,rgba(4, 127, 199, 1) ));
		background : -o-linear-gradient(0deg, rgba(6, 85, 153, 1) 0%, rgba(4, 127, 199, 1) 100%);
		background : -ms-linear-gradient(0deg, rgba(6, 85, 153, 1) 0%, rgba(4, 127, 199, 1) 100%);
		-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#065599', endColorstr='#047FC7' ,GradientType=0)";
		background : linear-gradient(90deg, rgba(6, 85, 153, 1) 0%, rgba(4, 127, 199, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#065599',endColorstr='#047FC7' , GradientType=1);
		border-top: 10px solid rgb(255, 255, 255);

		color: rgb(255, 255, 255);
		font-family: $font-featured;
		font-size: 0.8rem;
	}

		.footer .container
		{
			position: relative;
			padding-bottom: 45px;
		}

		.footer a
		{
			color: rgb(255, 255, 255);
		}

		.footer a:hover
		{
			color: $color-yellow;
		}

		.footer h6
		{
			margin: 0;
			padding: 0 0 15px 0;
			border-bottom: 5px solid rgba(255, 255, 255, 0.2);

			font-size: 0.9rem;
			font-weight: 700;
			color: rgb(255, 255, 255);
		}

		.footer .social
		{
			font-size: 2rem;
		}

		.footer .nav
		{
			margin: 15px 0;
			display: block;
		}

		.footer .nav .nav-link
		{
			display: block;
			padding: 0;
			margin: 5px 0;
		}
		
		.footer .footnotes
		{
			text-align: right;
		}

		.footer .f-logo
		{
			position: absolute;
			bottom: 0;
			right: 0;
		}

		.footer .btn-info
		{
			margin: 0 0 50px;
		}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer
		{
			padding: 40px 10px 0 10px;

			text-align: center;
			font-size: 0.9rem;
		}

		.footer .container
		{
			padding-bottom: 0px;
		}

		.footer .nav
		{
			margin: 15px 0 30px 0;
			display: block;
		}

		.footer .footnotes
		{
			margin: 20px 0;

			text-align: center;
		}

		.footer .f-logo
		{
			position: relative;
		}
	}

	/*=========================================================================*/

	/* [Table as row override Styles] */

	table.table-as-row > tbody > tr:before,
	table.table-as-row > tbody > tr:after {
		content: " ";
		display: table;
		clear: both;
	}

	table.table-as-row > tbody > tr {
		height: auto !important;
	}

	table.table-as-row,
	table.table-as-row > tbody,
	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
	}

	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
		margin-right: -15px;
		margin-left: -15px;
	}

	table.table-as-row > tbody > tr > td[class*=col-] {
		display: block;
		height: auto !important;
		margin-bottom: 20px;
	}

	/* col-lg */
	@media (min-width: 1200px) {
		table.table-as-row > tbody > tr > td[class*=col-lg-] {
			float: left;
		}
	}

	/* col-md */
	@media (min-width: 992px) {
		table.table-as-row > tbody > tr > td[class*=col-md-] {
			float: left;
		}
	}

	/* col-sm */
	@media (min-width: 768px) {
		table.table-as-row > tbody > tr > td[class*=col-sm-] {
			float: left;
		}
	}

	/* col-xs */
	table.table-as-row > tbody > tr > td[class*=col-xs-] {
		float: left;
	}

	.fancybox-slide--iframe .fancybox-content {
		width  : 800px;
		height : 600px;
		max-width  : 80%;
		max-height : 80%;
		margin: 0;
	}